<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">供应链赋能·方案详情</div>
      <div class="sub-title">供应链赋能增长策略</div>
      <div class="large-paragraph-describe">
        全球蛙对供应链端的多方调研，深刻体会到零售商与品牌商不同的侧重点，构筑云蛙智慧零售“蛙选联盟”。蛙选联盟为零售商与品牌商提供全域全渠道零售·分销·批销数智化SaaS系统，通过开展一件代发/批销集采/预售商品/尾货清仓四大创新销售模式，并利用数字化特有的“链接”优势，联合供销两端共筑公私域，打破商品壁垒，提升流量，打造新生态，助力零售商与品牌商实现流通效率提升、业绩增长！
      </div>
    </div>
    <div
      class="commodity-power-bg-image-container"
      style="
        --container-width: 750;
        --container-height: 742;
        --image-width: 216;
        --image-height: 246;
        --offset: 30;
      "
    >
      <img
        class="large-image-h246"
        v-for="src in subscriptImages"
        :key="src"
        :src="src"
      />
    </div>
    <img class="large-image-h426" src="@/image/retail/img_union_ isc@3x.png" />
    <div class="func-module-container">
      <div class="func-title">供应链赋能·应用案例</div>
      <div class="func-sub-title">C2M新品助力商超线上销售新增长</div>
      <div class="func-describe">
        2020年11月5日，由全球蛙为北京市昌平新世纪商城定制开发的“北京昌平新世纪商城”小程序正式上线，为全力帮助该企业首场线上营销旗开得胜，一方面，全球蛙为首场活动商城O2O商品的选择召开选品会、提供专业的选品建议；另一方面，全球蛙经过数据调研，为商城提供了包含农特土货、原创国货、进口洋货在内的海量C2M精选补位好货。O2O+C2M形成协同效应，为顾客打造多样化的购物体验。
      </div>
    </div>
    <div class="commodity-power-footer-image-container">
      <img
        class="large-image-h640"
        src="@/image/retail/img_cpxsj_ isc@3x.png"
      />
      <img
        class="large-image-h362"
        src="@/image/retail/img_result_ isc@3x.png"
      />
    </div>
    <action-sheet module="新零售供应链赋能">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
export default {
  data: () => ({
    subscriptImages: [
      require("@/image/retail/img_edm_ isc@3x.png"),
      require("@/image/retail/img_category_ isc@3x.png"),
      require("@/image/retail/img_brand_ isc@3x.png"),
      require("@/image/retail/img_market_ isc@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.commodity-power-bg-image-container {
  position: relative;
  width: 100%;
  height: 742px;
  background-color: $bgColorfff;
  background: url("../../../image/retail/img_phone_ isc@3x.png") no-repeat;
  background-size: 360px 536px;
  background-position: center;
  margin-bottom: 100px;
  .large-image-common-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: linear;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
  }
  @for $i from 1 through 4 {
    .large-image-h246:nth-of-type(#{$i}) {
      @extend .large-image-common-style;
      animation-name: move#{$i};
    }
  }
}
.large-image-h426 {
  margin-bottom: 60px;
}
.commodity-power-footer-image-container {
  background-color: $bgColorf2f5fa !important;
}
.func-module-container {
  padding-bottom: 0 !important;
}
.large-image-h362,
.large-image-h640 {
  margin-bottom: 120px;
}
@keyframes move1 {
  to {
    transform: translate(
      calc((var(--container-width) / 2 - var(--offset)) * -1px),
      calc(var(--container-height) / 2 * -1px)
    );
  }
}
@keyframes move2 {
  to {
    transform: translate(
      calc(
        (var(--container-width) / 2 - var(--image-width) - var(--offset)) * 1px
      ),
      calc(var(--container-height) / 2 * -1px)
    );
  }
}
@keyframes move3 {
  to {
    transform: translate(
      calc((var(--container-width) / 2 - var(--offset)) * -1px),
      calc((var(--container-height) / 2 - var(--image-height)) * 1px)
    );
  }
}
@keyframes move4 {
  to {
    transform: translate(
      calc(
        (var(--container-width) / 2 - var(--image-width) - var(--offset)) * 1px
      ),
      calc((var(--container-height) / 2 - var(--image-height)) * 1px)
    );
  }
}
</style>
